import { isEmpty } from 'lodash';
import { Spin } from 'antd';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { appUpdatePassword } from '../analytics/events';
import { Nullable } from '../../../types';
import { ResetPasswordTokenStatus } from '../../../api/graphql/types';
import fireEvent from '../../../../analytics/fireEvent';
import {
  UpdateUserPasswordMutation,
  ValidateResetPasswordTokenMutation,
} from '../../../api/graphql/mutations';
import PasswordTokenStatusComponent from '../components/PasswordTokenStatus';
import useValidateResetPasswordToken from '../hooks/useValidateResetPasswordToken';
import useUpdateUserPassword from '../hooks/useUpdateUserPassword';
import UpdatePasswordForm, {
  UpdatePasswordFormData,
} from '../components/UpdatePasswordForm';

const LOGIN_URL = '/login?password_updated=true';

export default function ResetPasswordForm() {
  const [searchParams] = useSearchParams();
  const [passwordTokenStatus, setPasswordTokenStatus] = useState<
    Nullable<ResetPasswordTokenStatus>
  >(null);

  const onValidateTokenCompleted = (
    data: ValidateResetPasswordTokenMutation,
  ) => {
    const resetPasswordTokenStatus =
      data?.validateResetPasswordToken?.resetPasswordTokenStatus;

    if (!resetPasswordTokenStatus) {
      setPasswordTokenStatus(null);
      return;
    }

    setPasswordTokenStatus(resetPasswordTokenStatus);
  };
  const {
    loading: loadingValidateToken,
    validateResetPasswordToken,
  } = useValidateResetPasswordToken({
    onCompleted: onValidateTokenCompleted,
  });

  useEffect(() => {
    const resetPasswordToken = searchParams.get('reset_password_token');

    if (!resetPasswordToken) {
      setPasswordTokenStatus('invalid');
      return;
    }

    validateResetPasswordToken({
      token: resetPasswordToken,
    });
  }, []);

  const onUpdatePasswordCompleted = (data: UpdateUserPasswordMutation) => {
    const requestErrors = data.updateUserPassword?.errors;

    if (!requestErrors || isEmpty(requestErrors)) {
      fireEvent(appUpdatePassword);
      window.location.replace(LOGIN_URL);
      return;
    }

    setPasswordTokenStatus('invalid');
  };
  const { loading, updatePassword } = useUpdateUserPassword({
    onCompleted: onUpdatePasswordCompleted,
  });

  const onSubmit = ({ password }: UpdatePasswordFormData) => {
    const token = searchParams.get('reset_password_token');

    if (!token) return;

    updatePassword({ password, token });
  };

  if (loadingValidateToken) return <Spin />;

  if (passwordTokenStatus === 'invalid' || passwordTokenStatus === 'expired')
    return <PasswordTokenStatusComponent status={passwordTokenStatus} />;

  return <UpdatePasswordForm onSubmit={onSubmit} loading={loading} />;
}
