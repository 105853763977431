import React, { useEffect } from 'react';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useFela } from 'react-fela';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatToPhone } from 'brazilian-values';
import { omit } from 'lodash';

import { FelaStyle } from '../../../../fela';
import colors from '../../../colors';
import {
  FormCheckbox,
  FormInput,
  FormPassword,
  ItemLabel,
} from '../../../forms/components';
import MyworkLogo from '../../Logo';
import useSubdomain from '../../../hooks/useSubdomain';
import baseValidationSchema from '../validations/schema';

const { Title } = Typography;

export type SignupFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  termsOfUse: boolean;
  contactPermission: boolean;
};
export type SignupFormErrors = [field: keyof SignupFormData, message: string][];

export type SignupFormProps = {
  loading?: boolean;
  onSubmit?: (data: SignupFormData) => void;
  errors?: SignupFormErrors;
  onEmailChange?: (email: string) => void;
  onPhoneNumberChange?: (phoneNumber: string) => void;
  invitationValues?: SignupFormData;
  hasContactPermissionItem?: boolean;
};

export const defaultFormValues: SignupFormData = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  termsOfUse: false,
  contactPermission: false,
};
const TERMS_OF_USE_URL = 'https://www.mywork.com.br/termos-de-uso';
const PRIVACY_POLICY_URL = 'https://www.mywork.com.br/politica-de-privacidade';

export default function SignupForm({
  onSubmit = () => {},
  loading = false,
  errors = [],
  onEmailChange = () => {},
  onPhoneNumberChange = () => {},
  invitationValues,
  hasContactPermissionItem = false,
}: SignupFormProps) {
  const { css } = useFela();
  const { t } = useTranslation();
  const subdomain = useSubdomain();

  const validationSchema = hasContactPermissionItem
    ? baseValidationSchema
    : baseValidationSchema.omit(['contactPermission']);

  const defaultValues = hasContactPermissionItem
    ? defaultFormValues
    : omit(defaultFormValues, ['contactPermission']);

  const {
    control,
    setError,
    handleSubmit: onFormSubmit,
    watch,
    setValue,
  } = useForm<SignupFormData>({
    criteriaMode: 'firstError',
    defaultValues,
    delayError: 200,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!errors) return;

    errors.forEach(([field, message]) => {
      setError(field, { message });
    });
  }, [errors]);

  useEffect(() => {
    if (!invitationValues) return;

    Object.entries(invitationValues).forEach(([field, value]) => {
      setValue(field as keyof SignupFormData, value);
    });
  }, [invitationValues]);

  const termsOfUseLabel = (
    <Trans
      i18nKey="signupForm.labels.termsOfUse"
      components={[
        <Link className={css(styles.link)} to={TERMS_OF_USE_URL} />,
        <Link className={css(styles.link)} to={PRIVACY_POLICY_URL} />,
      ]}
    />
  );
  const isTermsOfUseChecked = watch('termsOfUse');

  const contactPermissionLabel = (
    <Trans i18nKey="signupForm.labels.contactPermission" />
  );
  const isContactPermissionChecked = watch('contactPermission');

  const signupButtonDisabled =
    loading ||
    !isTermsOfUseChecked ||
    (hasContactPermissionItem && !isContactPermissionChecked);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.logo)}>
        <MyworkLogo height={50} />
      </div>
      <Title level={5} className={css(styles.title)}>
        {t(`signupForm.title.${subdomain}`)}
      </Title>
      <form onSubmit={onFormSubmit(onSubmit)}>
        <ItemLabel label={t('signupForm.labels.firstName')} required>
          <FormInput name="firstName" control={control} />
        </ItemLabel>
        <ItemLabel label={t('signupForm.labels.lastName')} required>
          <FormInput name="lastName" control={control} />
        </ItemLabel>
        <ItemLabel label={t('signupForm.labels.email')} required>
          <FormInput
            name="email"
            control={control}
            onBlur={(e) => onEmailChange(e.target.value)}
          />
        </ItemLabel>
        <ItemLabel
          label={t('signupForm.labels.phoneNumber')}
          required
          requiredText={t('signupForm.labels.cellphoneRecommended')}
        >
          <FormInput
            name="phoneNumber"
            control={control}
            formatter={formatToPhone}
            onBlur={(e) => onPhoneNumberChange(e.target.value)}
          />
        </ItemLabel>
        <ItemLabel label={t('signupForm.labels.password')} required>
          <FormPassword name="password" control={control} />
        </ItemLabel>
        <ItemLabel label={t('signupForm.labels.confirmPassword')} required>
          <FormPassword name="confirmPassword" control={control} />
        </ItemLabel>
        <FormCheckbox
          name="termsOfUse"
          control={control}
          className={css(styles.checkbox)}
          label={termsOfUseLabel}
        />
        {hasContactPermissionItem && (
          <FormCheckbox
            name="contactPermission"
            control={control}
            className={css(styles.checkbox)}
            label={contactPermissionLabel}
          />
        )}
        <Button
          className={css(styles.submit)}
          type="primary"
          htmlType="submit"
          disabled={signupButtonDisabled}
          loading={loading}
        >
          {t(`signupForm.submit.${subdomain}`)}
        </Button>
      </form>
    </div>
  );
}

const styles: FelaStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3rem 0rem',
    '@media (min-width: 320px)': {
      width: '17rem',
    },
    '@media (min-width: 768px)': {
      width: '22rem',
    },
    '@media (min-width: 1728px)': {
      width: '25.5rem',
    },
  },
  logo: {
    alignSelf: 'center',
    paddingBottom: '1.5rem',
  },
  title: {
    alignSelf: 'center',
    color: colors.text.lightGray,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
    margin: '0.5rem 0rem',
    marginBottom: '1rem',
  },
  checkbox: {
    lineHeight: '1.25rem',
    fontSize: '0.75rem',
    marginBottom: '1rem',
    marginLeft: '0rem',
    '> span': {
      lineHeight: '1.25rem',
    },
  },
  submit: {
    marginTop: '0.5rem',
    width: '100%',
  },
  link: {
    textDecoration: 'underline',
  },
};
