import { AnalyticsEvent } from 'src/analytics/types';

export const appSignUp: AnalyticsEvent = {
  event: 'general_app_sign_up',
  category: 'general',
  action: 'app_sign_up',
};

export const appSignUpOpen: AnalyticsEvent = {
  event: 'general_app_sign_up_open',
  category: 'general',
  action: 'app_sign_up_open',
};

export const registrationCompanySelect: AnalyticsEvent = {
  event: 'general_registration_company_select',
  category: 'general',
  action: 'registration_company_select',
};
