import * as yup from 'yup';

import i18n from '../../../../i18n';

const NAME_MIN_LENGTH = 2;
const PASSWORD_MIN_LENGTH = 6;

const REQUIRED_MESSAGE = i18n.t('signupForm.validations.required');
const FIRST_NAME_MIN_LENGTH = i18n.t(
  'signupForm.validations.firstName.minLength',
  {
    minLength: NAME_MIN_LENGTH,
  },
);
const FIRST_NAME_INVALID_FORMAT = i18n.t(
  'signupForm.validations.firstName.invalidFormat',
);
const LAST_NAME_MIN_LENGTH = i18n.t(
  'signupForm.validations.lastName.minLength',
  {
    minLength: NAME_MIN_LENGTH,
  },
);
const LAST_NAME_INVALID_FORMAT = i18n.t(
  'signupForm.validations.lastName.invalidFormat',
);
const EMAIL_INVALID_FORMAT = i18n.t(
  'signupForm.validations.email.invalidFormat',
);
const PHONE_NUMBER_INVALID_FORMAT = i18n.t(
  'signupForm.validations.phoneNumber.invalidFormat',
);
const PASSWORD_MIN_LENGTH_MESSAGE = i18n.t(
  'signupForm.validations.password.minLength',
  {
    minLength: PASSWORD_MIN_LENGTH,
  },
);
const CONFIRM_PASSWORD_INVALID = i18n.t(
  'signupForm.validations.confirmPassword.invalidFormat',
);

const NAME_REGEX = /^[\w'\-,.][^0-9_!¡?÷¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/i;
const PHONE_NUMBER_REGEX = /(?:\()[0-9]{2}(?:\))\s?\d?\s?[0-9]{4}(?:-)[0-9]{4}$/;

const validationSchema = yup.object({
  firstName: yup
    .string()
    .min(NAME_MIN_LENGTH, FIRST_NAME_MIN_LENGTH)
    .matches(NAME_REGEX, FIRST_NAME_INVALID_FORMAT)
    .required(REQUIRED_MESSAGE),
  lastName: yup
    .string()
    .min(NAME_MIN_LENGTH, LAST_NAME_MIN_LENGTH)
    .matches(NAME_REGEX, LAST_NAME_INVALID_FORMAT)
    .required(REQUIRED_MESSAGE),
  email: yup.string().email(EMAIL_INVALID_FORMAT).required(REQUIRED_MESSAGE),
  phoneNumber: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(PHONE_NUMBER_REGEX, PHONE_NUMBER_INVALID_FORMAT),
  password: yup
    .string()
    .min(PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH_MESSAGE)
    .required(REQUIRED_MESSAGE),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], CONFIRM_PASSWORD_INVALID)
    .required(REQUIRED_MESSAGE),
  termsOfUse: yup.boolean().oneOf([true], REQUIRED_MESSAGE),
  contactPermission: yup.boolean().oneOf([true], REQUIRED_MESSAGE),
});

export default validationSchema;
