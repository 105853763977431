import { AnalyticsEvent } from './types';

export default (attributes: AnalyticsEvent) => {
  if (!window.dataLayer) window.dataLayer = [];

  const { eventCallback: originalCallback } = attributes;

  return new Promise<void>((resolve) => {
    window.dataLayer.push({
      ...attributes,
      eventCallback: () => {
        if (originalCallback) originalCallback();

        resolve();
      },
      eventTimeout: 2000,
    });
  });
};
