import React from 'react';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useFela } from 'react-fela';

import { FelaStyle } from '../../../fela';
import { getURLParameters } from '../../../util';
import { registrationCompanySelect } from './analytics/events';
import colors from '../../colors';
import Divider from '../../../commons/components/Divider';
import fireEvent from '../../../analytics/fireEvent';
import MyworkLogo from '../../../../../assets/images/mywork logo whitebg rect retina.svg';
import TimetrackingCompanyIcon from '../../../../../assets/images/company-referral.svg';
import TimetrackingPersonIcon from '../../../../../assets/images/timetracking.svg';
import VacationCompanyIcon from '../../../../../assets/images/vacation-company.svg';
import VacationPersonIcon from '../../../../../assets/images/vacation-employee.svg';
import LinkToLogin from '../LinkToLogin';

const SIGNUP_PAGE = '/cadastro/novo';
const VACATION_PRODUCT_KEY = 'vacation';
const TIMETRACKING_PRODUCT_KEY = 'timetracking';
const ICON_SIZE = 64;

const { Text } = Typography;

export default function SignupOptionsPage() {
  const { css } = useFela();
  const { t } = useTranslation();

  const { product } = getURLParameters();
  const productKey =
    product === VACATION_PRODUCT_KEY
      ? VACATION_PRODUCT_KEY
      : TIMETRACKING_PRODUCT_KEY;

  const CompanyOptionIcon =
    product === VACATION_PRODUCT_KEY
      ? VacationCompanyIcon
      : TimetrackingCompanyIcon;
  const PersonOptionIcon =
    product === VACATION_PRODUCT_KEY
      ? VacationPersonIcon
      : TimetrackingPersonIcon;

  return (
    <div className={css(styles.page)}>
      <div className={css(styles.container)}>
        <div className={css(styles.logoContainer)}>
          <MyworkLogo data-testid="mywork-logo" height={50} />
        </div>
        <div className={css(styles.companyOption)}>
          <div className={css(styles.optionLogo)}>
            <CompanyOptionIcon
              data-testid="company-option-icon"
              width={64}
              height={64}
            />
          </div>
          <Text strong className={css(styles.optionTitle)}>
            {t(`user.registration.options.${productKey}.companyOptionTitle`)}
          </Text>
          <Text className={css(styles.optionDescription)}>
            {t(
              `user.registration.options.${productKey}.companyOptionDescription`,
            )}
          </Text>
          <Link to={`${SIGNUP_PAGE}?product=${productKey}`}>
            <Button
              onClick={() => fireEvent(registrationCompanySelect)}
              className={css(styles.button)}
              size="middle"
              type="primary"
            >
              {t(`user.registration.options.${productKey}.companyCTA`)}
            </Button>
          </Link>
          <LinkToLogin />
        </div>
        <Divider>{t('user.registration.or')}</Divider>
        <div className={css(styles.singlePersonOption)}>
          <div className={css(styles.optionLogo)}>
            <PersonOptionIcon
              data-testid="person-option-icon"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          </div>
          <p className={css(styles.optionDescription)}>
            <Trans
              i18nKey={`user.registration.options.${productKey}.singlePersonOptionDescription`}
            />
          </p>
        </div>
      </div>
    </div>
  );
}

const styles: FelaStyle = {
  page: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    backgroundColor: colors.mywork.lightOrange,
  },
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    maxWidth: '29.5rem',
    padding: '3rem',
    '@media (max-width: 425px)': {
      width: '100%',
      padding: '1rem',
    },
  },
  logoContainer: {
    marginBottom: '2.5rem',
    alignItems: 'center',
  },
  companyOption: {
    alignItems: 'center',
    border: `0.0625rem solid ${colors.border.medium}`,
    borderRadius: '0.1875rem',
    color: colors.text.dark,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '1.25rem',
    padding: '1.5rem',
    ':hover': {
      borderColor: colors.mywork.base,
    },
  },
  optionLogo: {
    marginBottom: '1rem',
  },
  optionTitle: {
    color: colors.text.dark,
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
    textAlign: 'center',
    marginBottom: '0.5rem',
  },
  optionDescription: {
    color: colors.text.dark,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    textAlign: 'center',
    fontWeight: 400,
    marginBottom: '0.5rem',
  },
  button: {
    fontWeight: 700,
    margin: '0.5rem',
  },
  singlePersonOption: {
    alignItems: 'center',
    color: colors.text.dark,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0.625rem',
  },
};
