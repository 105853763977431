import ptBR from 'antd/lib/locale-provider/pt_BR';
import { merge as deepMerge } from 'lodash';

const antdLocale = {
  DatePicker: {
    lang: {
      dateFormat: 'DD/MM/YYYY',
      dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
      dayFormat: 'DD',
      monthBeforeYear: true,
    },
  },
  Empty: {
    description: 'Nenhum dado encontrado para o filtro aplicado',
  },
};

export default deepMerge(ptBR, antdLocale);
