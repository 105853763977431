import { useMutation } from '@apollo/client';

import {
  RESET_USER_PASSWORD,
  ResetUserPasswordMutation,
} from '../../../api/graphql/mutations';

type ResetUserPasswordMutationParams = {
  login: string;
};

type useAuthenticateUserProps = {
  onCompleted?: (data: ResetUserPasswordMutation) => void;
};

export default function useResetUserPassword({
  onCompleted,
}: useAuthenticateUserProps) {
  const [execute, { loading }] = useMutation<ResetUserPasswordMutation>(
    RESET_USER_PASSWORD,
    {
      onCompleted,
    },
  );

  const resetPassword = ({ login }: ResetUserPasswordMutationParams) =>
    execute({
      variables: {
        input: { login },
      },
    });

  return { loading, resetPassword };
}
