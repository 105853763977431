import React from 'react';
import { Input, InputProps, Typography } from 'antd';
import { useFela } from 'react-fela';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { FelaStyle } from '../../../fela';

const { Text } = Typography;
const { Password } = Input;

export type FormInputProps<T extends FieldValues> = UseControllerProps<T> &
  InputProps;

export default function FormInput<T extends FieldValues>({
  name,
  control,
  className,
}: FormInputProps<T>) {
  const {
    field: { ref, ...otherProps },
    formState: { errors },
  } = useController<T>({ name, control });
  const { css } = useFela();

  const error = errors[name];
  const inputStatus = error ? 'error' : undefined;

  return (
    <>
      <Password
        ref={ref}
        status={inputStatus}
        className={className}
        data-testid={`password-input-${name}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
      <Text className={css(styles.errorText)} type="danger">
        {error?.message}
      </Text>
    </>
  );
}

const styles: FelaStyle = {
  errorText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    margin: '0',
  },
};
