import React from 'react';
import { useFela } from 'react-fela';

import { FelaFunction, FelaStyle } from '../../../fela';
import colors from '../../colors';
import PromoContainer from '../../experimentation/containers/PromoContainer';
import SigninForm from './containers/SigninForm';
import useGetFeatureValue from '../../experimentation/hooks/useGetFeatureValue';
import useSubdomain from '../../hooks/useSubdomain';

const DEFAULT_BACKGROUND_COLOR = colors.mywork.lightOrange;
const DEFAULT_POSITION = 'left';
const DEFAULT_PROPORTION = 0.5;
const PROMO_FEATURE_FLAG_PREFIX = 'sign-in-promotion';
const FEATURE_KEY_PATH = 'sign-in-form-display';

const DEFAULT_FEATURE_PARAMS = {
  position: DEFAULT_POSITION,
  proportion: DEFAULT_PROPORTION,
  backgroundColor: DEFAULT_BACKGROUND_COLOR,
};

export default function SigninPage() {
  const subdomain = useSubdomain();
  const pageKey = subdomain === 'contador' ? 'accountant' : 'client';
  const featureKey = `${pageKey}-${FEATURE_KEY_PATH}`;
  const promoFlagPrefix = `${pageKey}-${PROMO_FEATURE_FLAG_PREFIX}`;

  const {
    position: horizontalFormPosition,
    proportion: horizontalProportion,
    backgroundColor,
  } = useGetFeatureValue(featureKey, DEFAULT_FEATURE_PARAMS);

  const { css } = useFela({
    horizontalProportion,
    horizontalFormPosition,
    backgroundColor,
  });

  return (
    <div className={css(page)}>
      <div className={css(form)}>
        <SigninForm />
      </div>
      <div className={css(promo)}>
        <div className={css({ ...styles.promo, backgroundColor })}>
          <PromoContainer featureFlagPrefix={promoFlagPrefix} />
        </div>
      </div>
    </div>
  );
}

type PageStyle = {
  horizontalFormPosition: string;
};
export const page: FelaFunction<PageStyle> = ({ horizontalFormPosition }) => {
  const { basePage } = styles;

  if (horizontalFormPosition === 'left')
    return { ...basePage, flexDirection: 'row' };

  return { ...basePage, flexDirection: 'row-reverse' };
};

type FormStyle = {
  horizontalProportion: number;
};
export const form: FelaFunction<FormStyle> = ({ horizontalProportion }) => {
  return {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: `${(horizontalProportion || DEFAULT_PROPORTION) * 100}%`,
    '@media (max-width: 768px)': {
      width: '100%',
    },
  };
};

type PromoStyle = {
  horizontalProportion: number;
  backgroundColor: string;
};
export const promo: FelaFunction<PromoStyle> = ({
  horizontalProportion,
  backgroundColor,
}) => {
  return {
    alignItems: 'center',
    backgroundColor,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: `${(1 - (horizontalProportion || DEFAULT_PROPORTION)) * 100}%`,
    '@media (max-width: 768px)': {
      display: 'none',
    },
  };
};

const styles: FelaStyle = {
  basePage: {
    display: 'flex',
    height: '100vh',
  },
  promo: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '28.5rem',
  },
};
