import { useMutation } from '@apollo/client';

import {
  UPDATE_USER_PASSWORD,
  UpdateUserPasswordMutation,
} from '../../../api/graphql/mutations';

type UpdateUserPasswordMutationParams = {
  password: string;
  token: string;
};

type useAuthenticateUserProps = {
  onCompleted?: (data: UpdateUserPasswordMutation) => void;
};

export default function useUpdateUserPassword({
  onCompleted,
}: useAuthenticateUserProps) {
  const [execute, { loading }] = useMutation<UpdateUserPasswordMutation>(
    UPDATE_USER_PASSWORD,
    {
      onCompleted,
    },
  );

  const updatePassword = ({
    password,
    token,
  }: UpdateUserPasswordMutationParams) =>
    execute({
      variables: {
        input: { password, token },
      },
    });

  return { loading, updatePassword };
}
