import React, { ReactNode } from 'react';
import { Checkbox, CheckboxProps } from 'antd';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

export type FormCheckboxProps<T extends FieldValues> = UseControllerProps<T> &
  CheckboxProps & { label?: ReactNode };

export default function FormCheckbox<T extends FieldValues>({
  name,
  label,
  control,
  className,
}: FormCheckboxProps<T>) {
  const {
    field: { ref, value, ...otherProps },
  } = useController<T>({ name, control });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Checkbox ref={ref} className={className} checked={value} {...otherProps}>
      {label}
    </Checkbox>
  );
}
