import React from 'react';

import Promo, { DisplayOrder } from '../../components/Promo/Promo';
import useGetFeatureValue from '../hooks/useGetFeatureValue';
import promoImages from '../images';

type FeatureParams = {
  alignment: 'center' | 'left' | 'right';
  displayOrder: DisplayOrder;
  footerImage?: string;
  footerText?: string;
  headerText?: string;
  mainImage?: string;
  paragraphText?: string;
  subHeaderText?: string;
};

const DEFAULT_FEATURE_PARAMS: FeatureParams = {
  alignment: 'left',
  displayOrder: [],
  footerImage: '',
  footerText: '',
  headerText: '',
  mainImage: '',
  paragraphText: '',
  subHeaderText: '',
};

type PromoContainerProps = {
  featureFlagPrefix: string;
};

const PromoContainer = ({ featureFlagPrefix }: PromoContainerProps) => {
  const {
    alignment,
    displayOrder,
    footerImage,
    footerText,
    headerText,
    mainImage,
    paragraphText,
    subHeaderText,
  } = useGetFeatureValue(
    `${featureFlagPrefix}-component`,
    DEFAULT_FEATURE_PARAMS,
  );

  return (
    <Promo
      alignment={alignment}
      headerText={headerText}
      subHeaderText={subHeaderText}
      mainImage={
        <img
          alt="mainImage"
          style={{ width: '456px' }}
          src={promoImages[mainImage as string]}
        />
      }
      paragraphText={paragraphText}
      footerImage={
        <img alt="footerImage" src={promoImages[footerImage as string]} />
      }
      footerText={footerText}
      displayOrder={displayOrder}
    />
  );
};

export default PromoContainer;
