import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getURLParameters } from 'src/util';

const SIGNUP_PAGE = '/cadastro';
const VACATION_PRODUCT_KEY = 'vacation';
const TIMETRACKING_PRODUCT_KEY = 'timetracking';

export default function DefaultRoute() {
  const { product } = getURLParameters();
  const navigate = useNavigate();

  const productKey =
    product === VACATION_PRODUCT_KEY
      ? VACATION_PRODUCT_KEY
      : TIMETRACKING_PRODUCT_KEY;

  useEffect(() => {
    if (product) {
      navigate(`${SIGNUP_PAGE}?product=${productKey}`);
      return;
    }

    navigate('/login');
  }, [product, navigate]);

  return <></>;
}
