import { useEffect, useState } from 'react';

const MYWORK_SUBDOMAINS = ['app', 'contador', 'admin'];

type ArrayElement<
  ArrayType extends readonly unknown[]
> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
type MyworkSubdomains = ArrayElement<['app', 'contador', 'admin']>;

export default function useSubdomain() {
  const [subdomain, setSubdomain] = useState<MyworkSubdomains>('app');

  useEffect(() => {
    const subdomain = window.location.hostname.split('.')[0];

    if (MYWORK_SUBDOMAINS.includes(subdomain as MyworkSubdomains)) {
      setSubdomain(subdomain as MyworkSubdomains);
    }
  }, []);

  return subdomain;
}
