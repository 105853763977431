const numeralLocale = {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  ordinal: () => 'º',
  currency: {
    symbol: 'R$',
  },
};

export default numeralLocale;
