/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext,
  PropsWithChildren,
  RefObject,
  useContext,
  useRef,
  useState,
} from 'react';
import { ConfigProvider as AntdProvider } from 'antd';
import { createRenderer } from 'fela';
import { I18nextProvider } from 'react-i18next';
import { RendererProvider as FelaProvider } from 'react-fela';
import { Theme as AntdTheme } from 'antd/lib/config-provider/context';
import { v4 as uuid } from 'uuid';

import { antdLocale } from '../util/locales';
import ExperimentationProvider from './ExperimentationProvider';
import i18n from '../i18n';
import PermissionsProvider from './PermissionsProvider';
import rendererConfig from '../fela/felaConfig';

type ReplacementTheme = AntdTheme;
type ApplicationProviderProps = {
  felaPrefix?: string;
  antdClassName?: string;
  theme?: ReplacementTheme;
} & PropsWithChildren<{}>;

export const ApplicationContext = createContext<{
  externalContainerId?: string;
  externalContainerRef?: RefObject<HTMLDivElement>;
}>({});

export default function ApplicationProvider({
  children,
  felaPrefix,
  antdClassName = 'antd-local',
  theme = {},
}: ApplicationProviderProps) {
  const [felaUniquePrefix] = useState(
    () => felaPrefix || `_${uuid().substring(0, 6)}-`,
  );
  const [externalContainerId] = useState(
    () => `antd-external-container-${uuid()}`,
  );

  const externalContainerRef = useRef<HTMLDivElement>(null);
  const felaRendererConfig = rendererConfig({
    selectorPrefix: felaUniquePrefix,
  });
  const getPopupContainer = () => externalContainerRef.current || document.body;

  AntdProvider.config({
    theme,
  });

  return (
    <AntdProvider locale={antdLocale} getPopupContainer={getPopupContainer}>
      <FelaProvider renderer={createRenderer(felaRendererConfig)}>
        <I18nextProvider i18n={i18n}>
          <PermissionsProvider>
            <ExperimentationProvider>
              <div className={antdClassName}>
                <div id={externalContainerId} ref={externalContainerRef} />
                <ApplicationContext.Provider
                  value={{ externalContainerId, externalContainerRef }}
                >
                  {children}
                </ApplicationContext.Provider>
              </div>
            </ExperimentationProvider>
          </PermissionsProvider>
        </I18nextProvider>
      </FelaProvider>
    </AntdProvider>
  );
}

export const useApplicationContextGetContainer = () => {
  const { externalContainerRef } = useContext(ApplicationContext);
  return () => externalContainerRef?.current || document.body;
};
