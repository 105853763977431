const defaultPositionOptions = {
  enableHighAccuracy: true,
  timeout: 10000,
  maximumAge: 30000,
};

export default class GeolocationService {
  static checkGeolocationObj() {
    if ('geolocation' in navigator) {
      return { locationStatus: 'disponível' };
    }

    return { locationStatus: 'indisponível' };
  }

  static getLocation(successAction, errorAction, positionOptions) {
    navigator.geolocation.getCurrentPosition(successAction, errorAction, {
      ...defaultPositionOptions,
      positionOptions,
    });
  }

  static cacheLocation() {
    navigator.geolocation.getCurrentPosition(
      () => {},
      () => {},
      {
        ...defaultPositionOptions,
        timeout: 5000,
      },
    );
  }
}
