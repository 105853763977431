import { useMutation } from '@apollo/client';

import {
  VALIDATE_EMAIL,
  ValidateEmailMutation,
} from '../../../api/graphql/mutations';

type ValidateEmailMutationParams = {
  email: string;
};

type useValidateEmailProps = {
  onCompleted?: (data: ValidateEmailMutation) => void;
};

export default function useValidateEmail({
  onCompleted,
}: useValidateEmailProps) {
  const [execute, { loading }] = useMutation<ValidateEmailMutation>(
    VALIDATE_EMAIL,
    {
      onCompleted,
    },
  );

  const validateEmail = ({ email }: ValidateEmailMutationParams) =>
    execute({
      variables: {
        input: { email },
      },
    });

  return { loading, validateEmail };
}
