import camelizeKeys from './camelizeKeys';

export default function getURLParameters() {
  const windowURL = window.location.search;
  const urlSearchParams = new URLSearchParams(windowURL);

  const entries = Array.from(urlSearchParams.entries());

  const initialObject: Record<string, string> = {};

  const paramsObject = entries.reduce((accumulated, [key, value]) => {
    if (!accumulated[key]) return { ...accumulated, [key]: value };

    return {
      ...accumulated,
      [key]: [...accumulated[key], value],
    };
  }, initialObject);

  return camelizeKeys(paramsObject);
}
