import passwordAccountant from '../../../../assets/images/promo/unauthenticated/password-accountant.png';
import passwordManager from '../../../../assets/images/promo/unauthenticated/password-manager.png';
import signinAccountant from '../../../../assets/images/promo/unauthenticated/signin-accountant.png';
import signinManager from '../../../../assets/images/promo/unauthenticated/signin-manager.png';
import signupAccountant from '../../../../assets/images/promo/unauthenticated/signup-accountant.png';
import signupManager from '../../../../assets/images/promo/unauthenticated/signup-manager.png';

const promoImages = {
  'password-accountant.png': passwordAccountant,
  'password-manager.png': passwordManager,
  'signin-accountant.png': signinAccountant,
  'signin-manager.png': signinManager,
  'signup-accountant.png': signupAccountant,
  'signup-manager.png': signupManager,
};

export default promoImages;
