import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useFela } from 'react-fela';
import { useTranslation, Trans } from 'react-i18next';

import { FelaStyle } from '../../fela';
import colors from '../colors';
import MyworkLogo from './Logo';
import SadFace from '../../../../assets/images/sad-face.svg';
import Divider from './Divider';

const { Text, Title } = Typography;

export type ErrorCodes = 404 | 500;
export type ErrorPageProps = {
  code: ErrorCodes;
};

const mapErrorToTitle = (code: ErrorCodes) => {
  switch (code) {
    case 404:
      return 'notFound';
    case 500:
      return 'serverError';
    default:
      return 'serverError';
  }
};

const WHATSAPP_SUPPORT =
  'https://api.whatsapp.com/send/?phone=551130423058&type=phone_number&app_absent=0';
const EMAIL_SUPPORT = 'mailto:contato@mywork.com.br';
const HOME_URL = '/';

export default function SignupForm({ code }: ErrorPageProps) {
  const { css } = useFela();
  const { t } = useTranslation();

  const errorKey = mapErrorToTitle(code);

  return (
    <div className={css(styles.page)}>
      <div className={css(styles.container)}>
        <div className={css(styles.logo)}>
          <MyworkLogo height={50} />
        </div>
        <Title level={5} className={css(styles.title)}>
          {t(`errors.${errorKey}`)}
        </Title>
        <div className={css(styles.logo)}>
          <SadFace />
        </div>
        <Text className={css(styles.text)}>
          <Trans
            i18nKey="errors.homePage"
            components={[<Link className={css(styles.link)} to={HOME_URL} />]}
          />
        </Text>
        <Divider />
        <Text className={css(styles.text)}>
          <Trans
            i18nKey="errors.supportContact"
            components={[
              <Link className={css(styles.link)} to={WHATSAPP_SUPPORT} />,
              <Link className={css(styles.link)} to={EMAIL_SUPPORT} />,
            ]}
          />
        </Text>
      </div>
    </div>
  );
}

const styles: FelaStyle = {
  page: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    backgroundColor: colors.mywork.lightOrange,
  },
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    maxWidth: '29.5rem',
    padding: '3rem',
    '@media (max-width: 425px)': {
      width: '100%',
      padding: '1rem',
    },
  },
  logo: {
    alignSelf: 'center',
    paddingBottom: '1.5rem',
  },
  title: {
    alignSelf: 'center',
    color: colors.text.lightGray,
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '2rem',
    margin: '0',
    textAlign: 'center',
    paddingBottom: '1.5rem',
  },
  link: {
    textDecoration: 'underline',
  },
  text: {
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '22px',
    textAlign: 'center',
  },
};
