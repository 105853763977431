import React, { useState } from 'react';
import { camelCase, isEmpty } from 'lodash';

import { BasicError } from '../../../api/graphql/types';
import fireEvent from '../../../../analytics/fireEvent';
import {
  SignupUserMutation,
  ValidateEmailMutation,
  ValidatePhoneNumberMutation,
} from '../../../api/graphql/mutations';
import SignupFormComponent, {
  SignupFormData,
  SignupFormErrors,
} from '../components/SignupForm';
import useUserSignup from '../hooks/useSignupUser';
import useValidateEmail from '../hooks/useValidateEmail';
import useValidatePhoneNumber from '../hooks/useValidatePhoneNumber';
import { appSignUp } from '../analytics/events';

export default function SignupForm() {
  const [errors, setErrors] = useState<SignupFormErrors>([]);

  const onSignupCompleted = (data: SignupUserMutation) => {
    const requestErrors = data.signupUser?.errors;

    if (!requestErrors || isEmpty(requestErrors)) {
      fireEvent(appSignUp);
      window.location.replace(`/`);
      return;
    }

    const mappedErrors = mapErrors(requestErrors);
    setErrors(mappedErrors);
  };
  const { loading, signUp } = useUserSignup({ onCompleted: onSignupCompleted });

  const onValidationCompleted = (
    data: ValidateEmailMutation | ValidatePhoneNumberMutation,
  ) => {
    const requestErrors =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data.validateEmail?.errors || data.validatePhoneNumber?.errors;

    if (!requestErrors || isEmpty(requestErrors)) {
      return;
    }

    const mappedErrors = mapErrors(requestErrors);
    setErrors([...errors, ...mappedErrors]);
  };
  const { validateEmail } = useValidateEmail({
    onCompleted: onValidationCompleted,
  });
  const { validatePhoneNumber } = useValidatePhoneNumber({
    onCompleted: onValidationCompleted,
  });

  const onEmailChange = (email: string) => {
    if (email.length <= 0) return;

    validateEmail({ email });
  };
  const onPhoneNumberChange = (phoneNumber: string) => {
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    if (formattedPhoneNumber.length <= 0) return;

    validatePhoneNumber({ phoneNumber: formatPhoneNumber(phoneNumber) });
  };

  const onSubmit = ({
    email,
    firstName,
    lastName,
    password,
    phoneNumber,
  }: SignupFormData) => {
    signUp({
      email,
      firstName,
      lastName,
      password,
      phoneNumber: formatPhoneNumber(phoneNumber),
    });
  };

  return (
    <SignupFormComponent
      errors={errors}
      loading={loading}
      onEmailChange={onEmailChange}
      onPhoneNumberChange={onPhoneNumberChange}
      onSubmit={onSubmit}
    />
  );
}

const mapErrors = (errors: BasicError[]) => {
  return errors
    .filter((error) => !!error.path && !isEmpty(error.path))
    .map(({ path, message }) => {
      const field = camelCase((path as string[])[0]) as keyof SignupFormData;

      return [field, message || ''];
    }) as SignupFormErrors;
};

const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/\D+/g, '');
};
