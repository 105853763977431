import React, { ReactElement, cloneElement } from 'react';
import { useFela } from 'react-fela';
import { Typography } from 'antd';

import { FelaFunction, FelaStyle } from '../../../fela';
import colors from '../../colors';

type Alignment = 'center' | 'left' | 'right';

export type PromoProps = {
  alignment: Alignment;
  headerText?: string;
  subHeaderText?: string;
  mainImage?: ReactElement;
  paragraphText?: string;
  footerImage?: ReactElement;
  footerText?: string;
};

export type DisplayOrder = Array<keyof Omit<PromoProps, 'alignment'>>;

export type ComponentProps = PromoProps & { displayOrder: DisplayOrder };

export default function Promo({
  alignment = 'left',
  headerText,
  subHeaderText,
  mainImage,
  paragraphText,
  footerImage,
  footerText,
  displayOrder,
}: ComponentProps) {
  const { css } = useFela({ alignment });
  const { Title, Paragraph: AntdParagraph } = Typography;

  const Header = () =>
    headerText ? (
      <Title className={css(styles.header, itemStyle)}>{headerText}</Title>
    ) : null;

  const Paragraph = ({ content }: { content: string }) => (
    <AntdParagraph className={css(styles.paragraph, itemStyle)}>
      {content}
    </AntdParagraph>
  );

  const items = {
    headerText: headerText && <Header key="headerText" />,
    subHeaderText: subHeaderText && (
      <Paragraph content={subHeaderText} key="subHeaderText" />
    ),
    mainImage: mainImage && (
      <div className={css(styles.item)} key="mainImage">
        {cloneElement(mainImage, { key: 'mainImage' })}
      </div>
    ),
    paragraphText: paragraphText && (
      <Paragraph content={paragraphText} key="paragraphText" />
    ),
    footerImage: footerImage && (
      <div className={css(styles.item)} key="footerImage">
        {cloneElement(footerImage, { key: 'footerImage' })}
      </div>
    ),
    footerText: footerText && (
      <Paragraph content={footerText} key="footerText" />
    ),
  };

  const orderedItems = displayOrder.map((item) => items[item]);
  const displayedItems = orderedItems.filter((item) => item);

  return <div className={css(containerStyle)}>{displayedItems}</div>;
}

export const containerStyle: FelaFunction<{ alignment: Alignment }> = ({
  alignment,
}) => {
  const flexDiretions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: flexDiretions[alignment as Alignment],
  };
};

const itemStyle: FelaFunction<{ alignment: Alignment }> = ({ alignment }) => ({
  display: 'flex',
  marginBottom: '0.5rem',
  marginTop: '0.5rem',
  textAlign: alignment,
});

const styles: FelaStyle = {
  header: {
    fontFamily: 'new-kansas',
    color: colors.mywork.base,
    fontWeight: '500',
  },
  paragraph: {
    fontFamily: 'rustica',
    color: colors.text.oilBlue,
  },
  item: {
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
  },
};
