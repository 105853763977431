import { isEmpty, forEach } from 'lodash';

import { camelcaseKeys } from 'src/util/changecaseKeys';

import readMetaTagObject from '../util/readMetaTagObject';
import fireEvent from './fireEvent';

const TAG_NAME = 'x-triggers';

export default () => {
  const payload = readMetaTagObject({ tagName: TAG_NAME });

  if (isEmpty(payload)) return;

  const { meta, ...otherEvents } = payload;

  if (meta) fireEvent({ event: 'meta', ...camelcaseKeys(meta) });
  forEach(otherEvents, (value, key) =>
    fireEvent({ event: key, ...camelcaseKeys(value) }),
  );
};
