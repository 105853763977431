import moment from 'moment';

moment.locale(window.navigator.language);

const formatDateElements = (selector: string) => {
  document.querySelectorAll(selector).forEach((element: HTMLElement) => {
    const { isoDate, format } = element.dataset;
    if (format === undefined || isoDate === undefined) return;

    // eslint-disable-next-line no-param-reassign
    element.innerText = moment(isoDate).local().format(format);
  });
};

export default formatDateElements;
