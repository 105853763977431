export default function activateMenuItem(menuItemId: string) {
  const menuSidebarCSS = '.sidebar .active';
  const activeMenuClass = 'active';

  const activeMenuItems = Array.from(document.querySelectorAll(menuSidebarCSS));

  if (activeMenuItems.length > 0) {
    activeMenuItems.map((menuItem) =>
      menuItem.classList.remove(activeMenuClass),
    );
  }

  const menuItem = document.getElementById(menuItemId);
  if (!menuItem) return;

  menuItem.classList.add(activeMenuClass);
}
