import { CamelCasedProperties, SnakeCasedProperties } from 'type-fest';

const isArray = (object: unknown): object is unknown[] => Array.isArray(object);
const isObjectCastable = (object: unknown) => object === Object(object);
const isFunction = (object: unknown) => typeof object === 'function';
const isComplexObject = (object: unknown) =>
  Object.getPrototypeOf(object) !== Object.prototype;

const isSimpleObject = (object: unknown): object is Record<string, unknown> =>
  isObjectCastable(object) &&
  !isComplexObject(object) &&
  !isArray(object) &&
  !isFunction(object);

const toCamel = (string: string) => {
  const snakeRegex = /([-_][a-z])/gi;

  return string.replace(snakeRegex, (matchedSubstring) =>
    matchedSubstring.toUpperCase().replace('-', '').replace('_', ''),
  );
};

const toSnake = (string: string) => {
  const camelRegex = /([A-Z])/g;

  return string.replace(camelRegex, (matchedSubstring) =>
    matchedSubstring
      .replace(matchedSubstring, `_${matchedSubstring}`)
      .toLowerCase(),
  );
};

const changecaseKeys = (
  object: unknown,
  changecaseFunction: (string: string) => string,
) => {
  if (isSimpleObject(object)) {
    const nodes = {};

    Object.keys(object).forEach((key) => {
      nodes[changecaseFunction(key)] = changecaseKeys(
        object[key],
        changecaseFunction,
      );
    });

    return nodes;
  }

  if (isArray(object)) {
    return object.map((arrayItem) =>
      changecaseKeys(arrayItem, changecaseFunction),
    );
  }

  return object;
};

const camelcaseKeys = <T>(object: T): CamelCasedProperties<T> =>
  changecaseKeys(object, toCamel);
const snakecaseKeys = <T>(object: T): SnakeCasedProperties<T> =>
  changecaseKeys(object, toSnake);

export { camelcaseKeys, snakecaseKeys, toSnake, toCamel };
