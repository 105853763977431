import React, { PropsWithChildren, useEffect } from 'react';
import { GrowthBookProvider as GrowthbookReactProvider } from '@growthbook/growthbook-react';
import uuid from 'uuid/v4';

import { getEmploymentMetaData } from '../util';
import growthbook from '../growthbook';

const LOCAL_STORAGE_DEVICE_ID = 'mywork/device-id';

export default function ExperimentationProvider({
  children,
}: PropsWithChildren<{}>) {
  useEffect(() => {
    growthbook.loadFeatures();
  }, []);

  const employmentMetaData = getEmploymentMetaData();
  const deviceId = getDeviceId();

  growthbook.setAttributes({ ...employmentMetaData, deviceId });

  return (
    <GrowthbookReactProvider growthbook={growthbook}>
      {children}
    </GrowthbookReactProvider>
  );
}

const getDeviceId = () => {
  const deviceId = localStorage.getItem(LOCAL_STORAGE_DEVICE_ID);

  if (deviceId) return deviceId;

  const newDeviceId = uuid();
  localStorage.setItem(LOCAL_STORAGE_DEVICE_ID, newDeviceId);

  return newDeviceId;
};
