import * as yup from 'yup';

import i18n from '../../../../i18n';

const REQUIRED_MESSAGE = i18n.t('user.login.errors.required');

const validationSchema = yup.object({
  login: yup.string().required(REQUIRED_MESSAGE),
  password: yup.string().required(REQUIRED_MESSAGE),
});

export default validationSchema;
