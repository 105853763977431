/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, FocusEventHandler } from 'react';
import { Input, InputProps, Typography } from 'antd';
import { useFela } from 'react-fela';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { FelaStyle } from '../../../fela';

const { Text } = Typography;

export type FormInputProps<T extends FieldValues> = UseControllerProps<T> &
  InputProps & { formatter?: (value: string) => string };

export default function FormInput<T extends FieldValues>({
  name,
  control,
  className,
  formatter = (value: string) => value,
  onChange = () => {},
  onBlur = () => {},
  ...fieldProps
}: FormInputProps<T>) {
  const {
    field: {
      ref,
      onChange: onFieldChange,
      onBlur: onFieldBlur,
      value,
      ...otherProps
    },
    formState: { errors },
  } = useController<T>({ name, control });
  const { css } = useFela();

  const error = errors[name];
  const inputStatus = error ? 'error' : undefined;

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    onFieldChange({ ...e, target: { value: formatter(e.target.value) } });
  };

  const onInputBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (!error) onBlur(e);
    onFieldBlur();
  };

  return (
    <>
      <Input
        ref={ref}
        className={className}
        status={inputStatus}
        onChange={onInputChange}
        onBlur={onInputBlur}
        value={value}
        {...fieldProps}
        {...otherProps}
      />
      <Text className={css(styles.errorText)} type="danger">
        {error?.message}
      </Text>
    </>
  );
}

const styles: FelaStyle = {
  errorText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    margin: '0',
  },
};
