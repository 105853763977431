import React from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import DefaultRoute from './DefaultRoute';
import ForgotPasswordPage from '../../commons/components/Password/ForgotPasswordPage';
import ResetPasswordPage from '../../commons/components/Password/ResetPasswordPage';
import SigninPage from '../../commons/components/UserSignin/SigninPage';
import SignupOptionsPage from '../../commons/components/UserSignup/SignupOptionsPage';
import SignupPage from '../../commons/components/UserSignup/SignupPage';
import ErrorPage from '../../commons/components/ErrorPage';

const ErrorBoundaryLayout = () => (
  <ErrorBoundary fallback={<ErrorPage code={500} />}>
    <Outlet />
  </ErrorBoundary>
);

const router = createBrowserRouter([
  {
    element: <ErrorBoundaryLayout />,
    children: [
      {
        path: '/',
        element: <DefaultRoute />,
      },
      {
        path: '/login',
        element: <SigninPage />,
      },
      {
        path: '/cadastro',
        element: <SignupOptionsPage />,
      },
      {
        path: '/cadastro/novo',
        element: <SignupPage />,
      },
      {
        path: '/senha/nova',
        element: <ForgotPasswordPage />,
      },
      {
        path: '/senha/editar',
        element: <ResetPasswordPage />,
      },
      {
        path: '/relatorios',
        element: <Navigate to="/login?redirect_to=relatorios" />,
      },
      {
        path: '*',
        element: <ErrorPage code={404} />,
      },
    ],
  },
]);

export default router;
