import { useMutation } from '@apollo/client';

import {
  VALIDATE_RESET_PASSWORD_TOKEN,
  ValidateResetPasswordTokenMutation,
} from '../../../api/graphql/mutations';

type ValidateResetPasswordTokenMutationParams = {
  token: string;
};

type useAuthenticateUserProps = {
  onCompleted?: (data: ValidateResetPasswordTokenMutation) => void;
};

export default function useValidateResetPasswordToken({
  onCompleted,
}: useAuthenticateUserProps) {
  const [
    execute,
    { loading },
  ] = useMutation<ValidateResetPasswordTokenMutation>(
    VALIDATE_RESET_PASSWORD_TOKEN,
    {
      onCompleted,
    },
  );

  const validateResetPasswordToken = ({
    token,
  }: ValidateResetPasswordTokenMutationParams) =>
    execute({
      variables: {
        input: { token },
      },
    });

  return { loading, validateResetPasswordToken };
}
