import React from 'react';
import { Button, Divider, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useFela } from 'react-fela';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FelaStyle } from '../../../../fela';
import { FormPassword, ItemLabel } from '../../../forms/components';
import colors from '../../../colors';
import MyworkLogo from '../../Logo';
import useSubdomain from '../../../hooks/useSubdomain';
import validationSchema from '../validations/updatePasswordSchema';

const { Title, Text } = Typography;

export type UpdatePasswordFormData = {
  password: string;
  confirmPassword: string;
};
export type UpdatePasswordFormProps = {
  loading?: boolean;
  onSubmit?: (data: UpdatePasswordFormData) => void;
};

const defaultValues: UpdatePasswordFormData = {
  password: '',
  confirmPassword: '',
};
const SIGNUP_PAGE = '/cadastro';
const SIGNIN_PAGE = '/login';

export default function UpdatePasswordForm({
  onSubmit = () => {},
  loading = false,
}: UpdatePasswordFormProps) {
  const { css } = useFela();
  const { t } = useTranslation();
  const subdomain = useSubdomain();
  const {
    control,
    handleSubmit: onFormSubmit,
  } = useForm<UpdatePasswordFormData>({
    criteriaMode: 'firstError',
    defaultValues,
    delayError: 200,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const managerApp = subdomain === 'app';

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.logo)}>
        <MyworkLogo height={50} />
      </div>
      <Title level={5} className={css(styles.title)}>
        {t('password.updatePasswordForm.title')}
      </Title>
      <form onSubmit={onFormSubmit(onSubmit)}>
        <ItemLabel
          felaStyle={styles.itemLabel}
          label={t('password.updatePasswordForm.labels.password')}
          required
        >
          <FormPassword name="password" control={control} />
        </ItemLabel>
        <ItemLabel
          felaStyle={styles.itemLabel}
          label={t('password.updatePasswordForm.labels.confirmPassword')}
          required
        >
          <FormPassword name="confirmPassword" control={control} />
        </ItemLabel>
        <Button
          className={css(styles.submit)}
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
        >
          {t('password.updatePasswordForm.submit')}
        </Button>
      </form>
      <Link
        className={css({ ...styles.link, ...styles.signin })}
        to={SIGNIN_PAGE}
      >
        {t('password.updatePasswordForm.signin')}
      </Link>
      {managerApp && (
        <>
          <Divider />
          <Text className={css(styles.signup)}>
            <Trans
              i18nKey="password.updatePasswordForm.signup"
              components={[
                <Link className={css(styles.link)} to={SIGNUP_PAGE} />,
              ]}
            />
          </Text>
        </>
      )}
    </div>
  );
}

const styles: FelaStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3rem 0rem',
    '@media (min-width: 320px)': {
      width: '17rem',
    },
    '@media (min-width: 768px)': {
      width: '22rem',
    },
    '@media (min-width: 1728px)': {
      width: '25.5rem',
    },
  },
  logo: {
    alignSelf: 'center',
    paddingBottom: '1.5rem',
  },
  title: {
    alignSelf: 'center',
    color: colors.text.lightGray,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
    margin: '0.5rem 0rem',
    marginBottom: '1rem',
  },
  checkbox: {
    lineHeight: '1.25rem',
    fontSize: '0.75rem',
    '> span': {
      lineHeight: '1.25rem',
    },
  },
  itemLabel: {
    marginTop: '0',
    marginBottom: '1.5rem',
  },
  submit: {
    width: '100%',
    marginBottom: '1.5rem',
  },
  signin: {
    alignSelf: 'center',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    marginBottom: '1.5rem',
  },
  signup: {
    alignSelf: 'center',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
  },
  link: {
    textDecoration: 'underline',
  },
};
