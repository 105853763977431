import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import 'antd/dist/antd.less';

import { pageReadyListener } from '../util';
import ApplicationProvider from '../containers/ApplicationProvider';
import client from '../commons/api/apollo';
import initializeTagging from '../analytics/initializeTagging';
import App from './App';

export default class Initializer {
  static render() {
    const unauthenticatedContainerId = 'unauthenticated-container';

    pageReadyListener(() => {
      initializeTagging();

      const container = document.getElementById(unauthenticatedContainerId);

      ReactDOM.render(
        <ApplicationProvider>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </ApplicationProvider>,
        container,
      );
    });
  }
}
