/* eslint-disable camelcase */
import { isUndefined, omitBy } from 'lodash';

import { AccountStage, BillingStage } from '../commons/api/v1/company';
import { EmploymentMetaData } from '../employment/store/types';
import { Role } from '../employment/types';
import { ClientSize } from '../analytics/helpers/mapClientSizeToLTV';

import readMetaTagObject from './readMetaTagObject';

export const EMPLOYMENT_META_DATA_TAG = 'x-employment-meta-data';

export type XEmploymentMetaData = {
  account_stage: AccountStage;
  billing_stage: BillingStage;
  client_id: number;
  company_has_address: boolean;
  company_id: number;
  company_name: string;
  company_has_any_active_accounting_contract: boolean;
  company_has_any_adjustment_report_shared: boolean;
  company_old_excel_reports_and_hour_bank_page_enabled: boolean;
  client_size?: ClientSize;
  employment_id: number;
  employment_role: Role;
  first_attached_user_id: number;
  marketing_event_sent: boolean;
  override_feature_timetracking_excel_reports: boolean;
  user_cpf: string;
  user_email: string;
  user_first_name: string;
  user_hash: string;
  user_has_active_accountant: boolean;
  user_id: number;
  user_phone_number: string;
  won_probability: number;
};

export default function getEmploymentMetaData() {
  const {
    account_stage: accountStage,
    billing_stage: billingStage,
    client_id: clientId,
    company_has_address: companyHasAddress,
    company_id: companyId,
    company_name: companyName,
    company_has_any_active_accounting_contract: companyHasAnyActiveAccountingContract,
    company_has_any_adjustment_report_shared: companyHasAnyAdjustmentReportShared,
    company_old_excel_reports_and_hour_bank_page_enabled: companyOldExcelReportsAndHourBankPageEnabled,
    client_size: clientSize,
    employment_id: employmentId,
    employment_role: employmentRole,
    first_attached_user_id: firstAttachedUserId,
    marketing_event_sent: marketingEventSent,
    override_feature_timetracking_excel_reports: overrideFeatureTimetrackingExcelReports,
    user_cpf: userCpf,
    user_email: userEmail,
    user_first_name: userFirstName,
    user_hash: userHash,
    user_has_active_accountant: userHasActiveAccountant,
    user_id: userId,
    user_phone_number: userPhoneNumber,
    won_probability: wonProbability,
  }: XEmploymentMetaData = readMetaTagObject({
    tagName: EMPLOYMENT_META_DATA_TAG,
  });

  const metaData = {
    accountStage,
    billingStage,
    clientId,
    companyHasAddress,
    companyId,
    companyName,
    companyHasAnyActiveAccountingContract,
    companyHasAnyAdjustmentReportShared,
    companyOldExcelReportsAndHourBankPageEnabled,
    clientSize,
    employmentId,
    employmentRole,
    firstAttachedUserId,
    marketingEventSent,
    overrideFeatureTimetrackingExcelReports,
    userCpf,
    userEmail,
    userFirstName,
    userHash,
    userHasActiveAccountant,
    userId,
    userPhoneNumber,
    wonProbability,
  };

  return omitBy(metaData, isUndefined) as EmploymentMetaData;
}
