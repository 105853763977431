import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const errorData = gql`
  fragment ErrorData on BasicError {
    message
    path
    extensions {
      code
      validation
    }
  }
`;

export const benefitErrorData = gql`
  fragment BenefitErrorData on BenefitBasicError {
    message
    path
    extensions {
      code
    }
  }
`;
