/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import MyworkLogo from '../../../../assets/images/mywork logo whitebg rect retina.svg';
import AccountantLogo from '../../../../assets/images/logo-mywork-contador.svg';

import useSubdomain from '../hooks/useSubdomain';

export default function Logo(props) {
  const subdomain = useSubdomain();

  if (subdomain === 'contador') return <AccountantLogo {...props} />;

  return <MyworkLogo {...props} />;
}
