import { isEmpty } from 'lodash';

import getMetaContent from './getMetaContent';

export default function readMetaTagObject({ tagName }: { tagName: string }) {
  const content = getMetaContent(tagName);
  if (isEmpty(content)) return {};

  return JSON.parse(b64DecodeUnicode(content));
}

function b64DecodeUnicode(content: string) {
  return decodeURIComponent(
    Array.prototype.map
      .call(
        atob(content),
        (character: string) =>
          `%${`00${character.charCodeAt(0).toString(16)}`.slice(-2)}`,
      )
      .join(''),
  );
}
