import { gql } from '@apollo/client';

import { Nullable } from '../../../commons/types';

import { benefitErrorData, errorData } from './fragments';
import {
  AccountingFirm,
  BasicError,
  CalculatedDay,
  CompanyAttributes,
  WorkSchedule,
  WorkDay,
  Benefit,
  Employment,
  Subscription,
  Vacation,
  VacationRequest,
  ValidatedAddress,
  User,
  ResetPasswordTokenStatus,
  AdjustmentReportCompressed,
} from './types';

export interface AdjustEmploymentHourBankMutation {
  adjustEmploymentHourBank: Nullable<{
    errors: Nullable<BasicError[]>;
    workDay: WorkDay;
  }>;
}

export const ADJUST_EMPLOYMENT_HOUR_BANK = gql`
  mutation AdjustEmploymentHourBank($input: AdjustEmploymentHourBankInput!) {
    adjustEmploymentHourBank(input: $input) {
      errors {
        ...ErrorData
      }
      workDay {
        id
      }
    }
  }
  ${errorData}
`;

export interface AdjustEmploymentHoursMutation {
  adjustEmploymentHours: Nullable<{
    errors: Nullable<BasicError[]>;
    calculatedDay: CalculatedDay;
  }>;
}

export const ADJUST_EMPLOYMENT_HOURS = gql`
  mutation AdjustEmploymentHours($input: AdjustEmploymentHoursInput!) {
    adjustEmploymentHours(input: $input) {
      errors {
        ...ErrorData
      }
      calculatedDay {
        id
      }
    }
  }
  ${errorData}
`;

export interface ResetInitialHourBankMutation {
  resetInitialHourBank: Nullable<{
    errors: Nullable<BasicError[]>;
    calculatedDay: CalculatedDay;
  }>;
}

export const RESET_INITIAL_HOUR_BANK = gql`
  mutation ResetInitialHourBank($input: ResetInitialHourBankInput!) {
    resetInitialHourBank(input: $input) {
      errors {
        ...ErrorData
      }
      calculatedDay {
        id
      }
    }
  }
  ${errorData}
`;

export interface AssociateWorkScheduleEmploymentsMutation {
  associateWorkScheduleEmployments: Nullable<{
    errors: Nullable<BasicError[]>;
  }>;
}

export interface OverrideOrCreateWithNameWorkScheduleMutation {
  overrideOrCreateWithNameWorkSchedule: Nullable<{
    created: Nullable<boolean>;
    errors: Nullable<BasicError[]>;
    workSchedule: Nullable<WorkSchedule>;
  }>;
}

export const ASSOCIATE_WORK_SCHEDULE_EMPLOYMENTS = gql`
  mutation AssociateWorkScheduleEmployments(
    $input: AssociateWorkScheduleEmploymentsInput!
  ) {
    associateWorkScheduleEmployments(input: $input) {
      errors {
        ...ErrorData
      }
    }
  }
  ${errorData}
`;

export const OVERRIDE_OR_CREATE_WITH_NAME_WORK_SCHEDULE = gql`
  mutation OverrideOrCreateWithNameWorkSchedule(
    $input: OverrideOrCreateWithNameWorkScheduleInput!
  ) {
    overrideOrCreateWithNameWorkSchedule(input: $input) {
      created
      errors {
        ...ErrorData
      }
      workSchedule {
        id
        name
        fingerprint
        nextStartDate
        companyId
        workScheduleDays {
          dayOrder
          flexIntervalMinutes
          flexScheduleMinutes
          overnight
          preAssigned
          workScheduleHours {
            hourOrder
            scheduleTime
          }
        }
      }
    }
  }
  ${errorData}
`;

export type UpdateCompanyData = Nullable<{
  errors: Nullable<BasicError[]>;
  company: CompanyAttributes;
}>;

export interface UpdateCompanyDataMutation {
  updateCompanyData: UpdateCompanyData;
}

export const UPDATE_COMPANY_DATA = gql`
  mutation UpdateCompanyData($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      errors {
        ...ErrorData
      }
      company {
        adjustedTimetrackingSuggestionEnabled
        clockOnly
        clockPhotoEnabled
        cnpj
        customizeReportLogo
        defaultDebitTimeAllocation
        geoPermissions
        hourBankBalanceEnabled
        name
        reminderEnabled
        timetrackingPhotoEnabled
        vacationRequestEnabled
        timezoneId
        timezone {
          id
          name
        }
        address {
          city
          complement
          country
          name
          neighborhood
          number
          postalCode
          state
        }
      }
    }
  }
  ${errorData}
`;

export interface UpdateCompanyEmploymentsDataMutation {
  updateCompanyEmploymentsData: Nullable<{
    errors: Nullable<BasicError[]>;
    updated: boolean;
  }>;
}

export const UPDATE_COMPANY_EMPLOYMENTS_DATA = gql`
  mutation UpdateCompanyEmploymentsData(
    $input: UpdateAllCompanyEmploymentsInput!
  ) {
    updateAllCompanyEmployments(input: $input) {
      errors {
        ...ErrorData
      }
      updated
    }
  }
  ${errorData}
`;

export interface RequestBenefitMutation {
  requestBenefit: Nullable<{
    errors: Nullable<BasicError[]>;
    benefit: Nullable<Benefit>;
    formData: string;
  }>;
}

export const REQUEST_BENEFIT = gql`
  mutation RequestBenefit($input: RequestBenefitInput!, $companyId: ID!) {
    requestBenefit(input: $input, companyId: $companyId) {
      errors {
        ...BenefitErrorData
      }
      formData
      benefit {
        id
        active
        baseRedirectUrl
        category
        description
        externalOnly
        formSchema
        productName
        provider
        redirect
        redirectUrlParameters
        shortDescription
        thumbnailUrl
      }
    }
  }
  ${benefitErrorData}
`;

export interface ChangePlanMutation {
  changePlan: Nullable<{
    errors: Nullable<BasicError[]>;
    updated: boolean;
  }>;
}

export const CHANGE_PLAN = gql`
  mutation ChangePlan($input: ChangePlanInput!) {
    changePlan(input: $input) {
      subscription {
        id
        beginTesting
        daysOfTesting
        testing
      }
      errors {
        ...ErrorData
      }
    }
  }
  ${errorData}
`;

export interface StartTrialMutation {
  startTrial: Nullable<{
    errors: Nullable<BasicError[]>;
    subscription: Nullable<Subscription>;
  }>;
}

export const START_TRIAL = gql`
  mutation StartTrial($input: StartTrialInput!) {
    startTrial(input: $input) {
      subscription {
        beginTesting
        createdAt
        daysOfTesting
        id
        testing
        updatedAt
      }
      errors {
        ...ErrorData
      }
    }
  }
  ${errorData}
`;

type EmploymentConnection = {
  edges: Array<{
    node: Employment;
  }>;
  totalCount: number;
};

export interface WaiveRangeMutation {
  waiveRange: Nullable<{
    employments: Nullable<EmploymentConnection>;
    errors: Nullable<BasicError[]>;
  }>;
}

export const WAIVE_RANGE = gql`
  mutation WaiveRange($input: WaiveRangeInput!) {
    waiveRange(input: $input) {
      employments {
        edges {
          node {
            id
          }
        }
        totalCount
      }
      errors {
        ...ErrorData
      }
    }
  }
  ${errorData}
`;

export interface DeleteVacation {
  deleteVacation: {
    errors: Nullable<BasicError[]>;
    deletedVacation: Nullable<Vacation>;
  };
}

export const DELETE_VACATION = gql`
  mutation DeleteVacation($input: DeleteVacationInput!) {
    deleteVacation(input: $input) {
      errors {
        message
        path
      }
      deletedVacation {
        id
        status
        startDate
        endDate
      }
    }
  }
`;
export interface CreateVacationMutation {
  createVacation: Nullable<{
    vacation: Nullable<Vacation>;
    errors: Nullable<BasicError[]>;
  }>;
}

export const CREATE_VACATION = gql`
  mutation CreateVacation($input: CreateVacationInput!) {
    createVacation(input: $input) {
      vacation {
        id
        startDate
        endDate
        status
      }
      errors {
        ...ErrorData
      }
    }
  }
  ${errorData}
`;

export interface AcceptVacationRequestMutation {
  acceptVacationRequest: {
    vacationRequest: Nullable<VacationRequest>;
    errors: Nullable<BasicError[]>;
  };
}

export const ACCEPT_VACATION_REQUEST = gql`
  mutation AcceptVacationRequest($input: AcceptVacationRequestInput!) {
    acceptVacationRequest(input: $input) {
      vacationRequest {
        createdAt
        endDate
        id
        requestReason
        requesterId
        reviewReason
        reviewedAt
        reviewerId
        startDate
        status
        updatedAt
      }
      errors {
        ...ErrorData
      }
    }
  }
  ${errorData}
`;

export interface RejectVacationRequestMutation {
  rejectVacationRequest: {
    vacationRequest: Nullable<VacationRequest>;
    errors: Nullable<BasicError[]>;
  };
}

export const REJECT_VACATION_REQUEST = gql`
  mutation RejectVacationRequest($input: RejectVacationRequestInput!) {
    rejectVacationRequest(input: $input) {
      vacationRequest {
        createdAt
        endDate
        id
        requestReason
        requesterId
        reviewReason
        reviewedAt
        reviewerId
        startDate
        status
        updatedAt
      }
      errors {
        ...ErrorData
      }
    }
  }
  ${errorData}
`;

export interface ValidateCreateVacationMutation {
  validateCreateVacation: Nullable<{
    valid: boolean;
    errors: Nullable<BasicError[]>;
  }>;
}

export const VALIDATE_CREATE_VACATION = gql`
  mutation ValidateCreateVacation($input: ValidateCreateVacationInput!) {
    validateCreateVacation(input: $input) {
      valid
      errors {
        extensions {
          code
          validation
        }
        message
        path
      }
    }
  }
`;

export interface ValidatePostalCodeMutation {
  validatePostalCode: Nullable<{
    validatedAddress: Nullable<ValidatedAddress>;
    errors: Nullable<BasicError[]>;
  }>;
}

export const VALIDATE_POSTAL_CODE = gql`
  mutation ValidatePostalCode($input: ValidatePostalCodeInput!) {
    validatePostalCode(input: $input) {
      validatedAddress {
        city
        neighborhood
        cep
        state
        street
      }
      errors {
        ...ErrorData
      }
    }
  }
  ${errorData}
`;

export interface SetClientMarketingEventSentMutation {
  setClientMarketingEventSent: Nullable<{
    errors: Nullable<BasicError[]>;
    success: boolean;
  }>;
}

export const SET_CLIENT_MARKETING_EVENT_SENT = gql`
  mutation SetClientMarketingEventSent(
    $input: SetClientMarketingEventSentInput!
  ) {
    setClientMarketingEventSent(input: $input) {
      errors {
        ...ErrorData
      }
      success
    }
  }
  ${errorData}
`;

export interface GenerateTechnicalCertificateMutation {
  generateTechnicalCertificate: Nullable<{
    company: Nullable<{
      id: string;
      name: string;
    }>;
    errors: Nullable<BasicError[]>;
  }>;
}
export const GENERATE_TECHNICAL_CERTIFICATE = gql`
  mutation generateTechnicalCertificate(
    $input: GenerateTechnicalCertificateInput!
  ) {
    generateTechnicalCertificate(input: $input) {
      errors {
        ...ErrorData
      }
      company {
        id
        name
      }
    }
  }
  ${errorData}
`;

export interface SignupUserMutation {
  signupUser: Nullable<{
    errors: Nullable<BasicError[]>;
    user: Nullable<{
      id: string;
    }>;
  }>;
}
export const SIGNUP_USER = gql`
  mutation SignupUser($input: SignupUserInput!) {
    signupUser(input: $input) {
      errors {
        ...ErrorData
      }
      user {
        id
      }
    }
  }
  ${errorData}
`;

export interface ValidateEmailMutation {
  validateEmail: Nullable<{
    errors: Nullable<BasicError[]>;
    valid: boolean;
  }>;
}

export const VALIDATE_EMAIL = gql`
  mutation ValidateEmail($input: ValidateEmailInput!) {
    validateEmail(input: $input) {
      errors {
        ...ErrorData
      }
      valid
    }
  }
  ${errorData}
`;

export interface ValidatePhoneNumberMutation {
  validatePhoneNumber: Nullable<{
    errors: Nullable<BasicError[]>;
    valid: boolean;
  }>;
}

export const VALIDATE_PHONE_NUMBER = gql`
  mutation ValidatePhoneNumber($input: ValidatePhoneNumberInput!) {
    validatePhoneNumber(input: $input) {
      errors {
        ...ErrorData
      }
      valid
    }
  }
  ${errorData}
`;

export interface AuthenticateUserMutation {
  authenticateUser: Nullable<{
    errors: Nullable<BasicError[]>;
    user: Nullable<User>;
  }>;
}

export const AUTHENTICATE_USER = gql`
  mutation AuthenticateUser($input: AuthenticateUserInput!) {
    authenticateUser(input: $input) {
      errors {
        ...ErrorData
      }
      user {
        status
        authenticationToken
      }
    }
  }
  ${errorData}
`;

export interface ResetUserPasswordMutation {
  resetUserPassword: Nullable<{
    errors: Nullable<BasicError[]>;
    user: Nullable<User>;
  }>;
}

export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      errors {
        ...ErrorData
      }
      user {
        id
      }
    }
  }
  ${errorData}
`;

export interface UpdateUserPasswordMutation {
  updateUserPassword: Nullable<{
    errors: Nullable<BasicError[]>;
    user: Nullable<User>;
  }>;
}

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      errors {
        message
      }
      user {
        id
      }
    }
  }
`;

export interface ValidateResetPasswordTokenMutation {
  validateResetPasswordToken: Nullable<{
    resetPasswordTokenStatus: Nullable<ResetPasswordTokenStatus>;
  }>;
}

export const VALIDATE_RESET_PASSWORD_TOKEN = gql`
  mutation ValidateResetPasswordToken(
    $input: ValidateResetPasswordTokenInput!
  ) {
    validateResetPasswordToken(input: $input) {
      resetPasswordTokenStatus
    }
  }
`;

export interface SignoutUserMutation {
  signoutUser: Nullable<{
    errors: Nullable<BasicError[]>;
    user: Nullable<{
      id: string;
    }>;
  }>;
}

export const SIGNOUT_USER = gql`
  mutation SignoutUser {
    signoutUser(input: {}) {
      errors {
        ...ErrorData
      }
      user {
        id
      }
    }
  }
  ${errorData}
`;

export interface CreateFirstAccountingFirmMutation {
  createFirstAccountingFirm: Nullable<{
    accountingFirm: Nullable<AccountingFirm>;
    errors: Nullable<BasicError[]>;
  }>;
}

export const CREATE_FIRST_ACCOUNTING_FIRM = gql`
  mutation CreateFirstAccountingFirm($input: CreateFirstAccountingFirmInput!) {
    createFirstAccountingFirm(input: $input) {
      errors {
        ...ErrorData
      }
      accountingFirm {
        id
        active
        name
        size
      }
    }
  }
  ${errorData}
`;

export interface AcceptAccountingFirmInvitationMutation {
  acceptAccountingFirmInvitation: Nullable<{
    accountingFirmInvitations: Array<{
      status: string;
    }>;
    errors: Nullable<BasicError[]>;
  }>;
}
export const ACCEPT_ACCOUNTING_FIRM_INVITATION = gql`
  mutation AcceptAccountingFirmInvitation(
    $input: AcceptAccountingFirmInvitationInput!
  ) {
    acceptAccountingFirmInvitation(input: $input) {
      errors {
        ...ErrorData
      }
      accountingFirmInvitations {
        status
      }
    }
  }
  ${errorData}
`;

export interface ShareAdjustmentReportCompressedMutation {
  shareAdjustmentReportCompressed: Nullable<{
    adjustmentReportCompressed: Nullable<AdjustmentReportCompressed>;
    errors: Nullable<BasicError[]>;
  }>;
}

export const SHARE_ADJUSTMENT_REPORT_COMPRESSED = gql`
  mutation ShareAdjustmentReportCompressed(
    $input: ShareAdjustmentReportCompressedInput!
  ) {
    shareAdjustmentReportCompressed(input: $input) {
      adjustmentReportCompressed {
        createdAt
        id
        requester {
          id
        }
        requesterId
        s3ObjectKey
        shared
        sharedAt
        sharedBy {
          id
        }
        sharedById
        startDate
        status
        statusMessage
        updatedAt
      }
      errors {
        ...ErrorData
      }
    }
  }
  ${errorData}
`;

export interface InviteAccountantMutation {
  inviteAccountant: Nullable<{
    adjustmentReportCompressed: Nullable<AdjustmentReportCompressed>;
    invitationUrl: Nullable<string>;
    errors: Nullable<BasicError[]>;
  }>;
}

export const INVITE_ACCOUNTANT = gql`
  mutation InviteAccountantMutation($input: InviteAccountantInput!) {
    inviteAccountant(input: $input) {
      adjustmentReportCompressed {
        createdAt
        id
        requester {
          id
        }
        requesterId
        s3ObjectKey
        shared
        sharedAt
        sharedBy {
          id
        }
        sharedById
        startDate
        status
        statusMessage
        updatedAt
      }
      invitationUrl
      errors {
        ...ErrorData
      }
    }
  }
  ${errorData}
`;

export interface CreateAccountantUserMutation {
  createAccountantUser: Nullable<{
    errors: Nullable<BasicError[]>;
    user: Nullable<User>;
  }>;
}

export const CREATE_ACCOUNTANT_USER = gql`
  mutation CreateAccountantUser($input: CreateAccountantUserInput!) {
    createAccountantUser(input: $input) {
      errors {
        ...ErrorData
      }
      user {
        id
      }
    }
  }
  ${errorData}
`;

export interface CreateAccountingFirmInvitationMutation {
  createAccountingFirmInvitation: Nullable<{
    invitationUrl: Nullable<string>;
    errors: Nullable<BasicError[]>;
  }>;
}

export const CREATE_ACCOUNTING_FIRM_INVITATION = gql`
  mutation CreateAccountingFirmInvitationMutation(
    $input: CreateAccountingFirmInvitationInput!
  ) {
    createAccountingFirmInvitation(input: $input) {
      invitationUrl
      errors {
        ...ErrorData
      }
    }
  }
  ${errorData}
`;
