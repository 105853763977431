import React, { PropsWithChildren } from 'react';
import { Divider as AntdDivider } from 'antd';
import { useFela } from 'react-fela';

import colors from '../../colors';
import { FelaStyle } from '../../../fela';

export default function Divider({ children }: PropsWithChildren<{}>) {
  const { css } = useFela();

  return (
    <div className={css(styles.container)}>
      <AntdDivider className={css(styles.divider)}>{children}</AntdDivider>
    </div>
  );
}

const styles: FelaStyle = {
  container: {
    display: 'block',
    width: '100%',
  },
  divider: {
    '::before': {
      borderTop: `0.0625rem solid ${colors.border.medium}`,
    },
    '::after': {
      borderTop: `0.0625rem solid ${colors.border.medium}`,
    },
  },
};
