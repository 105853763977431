import { AnalyticsEvent } from 'src/analytics/types';

export const appUpdatePassword: AnalyticsEvent = {
  event: 'general_app_update_password',
  category: 'general',
  action: 'app_update_password',
};

export const appRecoverPasswordEmailSent: AnalyticsEvent = {
  event: 'general_recover_password_email_sent',
  category: 'general',
  action: 'recover_password_email_sent',
};
