import moment from 'moment';
import 'moment/locale/pt-br';

export default function localizedWrittenDate(date) {
  const dateStr = moment(date)
    .locale('pt-br')
    .format('DD [de] MMMM [de] YYYY')
    .toLowerCase();

  return dateStr;
}
