/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Select, SelectProps, Typography } from 'antd';
import { useFela } from 'react-fela';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { FelaStyle } from '../../../fela';

const { Text } = Typography;

export type FormSelectProps<T extends FieldValues> = UseControllerProps<T> &
  SelectProps & { formatter?: (value: string) => string };

export default function FormSelect<T extends FieldValues>({
  name,
  control,
  className,
  ...fieldProps
}: FormSelectProps<T>) {
  const {
    field: { ref, value, ...otherProps },
    formState: { errors },
  } = useController<T>({ name, control });
  const { css } = useFela();

  const error = errors[name];
  const selectStatus = error ? 'error' : undefined;

  return (
    <>
      <Select
        ref={ref}
        className={className}
        status={selectStatus}
        value={value}
        {...fieldProps}
        {...otherProps}
      />
      <Text className={css(styles.errorText)} type="danger">
        {error?.message}
      </Text>
    </>
  );
}

const styles: FelaStyle = {
  errorText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    margin: '0',
  },
};
