import i18n, { InitOptions, Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ptBR } from '../util/translations';

const resources: Resource = {
  'pt-BR': {
    translation: ptBR,
  },
};

const config: InitOptions = {
  resources,
  debug: false,
  lng: 'pt-BR',
};

i18n.use(initReactI18next).init(config);

export default i18n;
