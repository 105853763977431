import React from 'react';
import { Divider, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useFela } from 'react-fela';
import { useTranslation, Trans } from 'react-i18next';

import { FelaStyle } from '../../../../fela';
import colors from '../../../colors';
import MyworkLogo from '../../Logo';
import SadFace from '../../../../../../assets/images/sad-face.svg';

const { Title, Text } = Typography;
const SIGNUP_PAGE = '/cadastro';
const FORGOT_PASSWORD_PAGE = '/senha/nova';

export type PasswordTokenStatusProps = {
  status: 'invalid' | 'expired';
};

export default function PassworkTokenStatus({
  status,
}: PasswordTokenStatusProps) {
  const { css } = useFela();
  const { t } = useTranslation();

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.logo)}>
        <MyworkLogo height={50} />
      </div>
      <Title level={5} className={css(styles.title)}>
        {t(`password.token.${status}`)}
      </Title>
      <div className={css(styles.logo)}>
        <SadFace />
      </div>
      <Link
        className={css({ ...styles.link, ...styles.forgotPassword })}
        to={FORGOT_PASSWORD_PAGE}
      >
        {t('password.token.forgotPassword')}
      </Link>
      <Divider />
      <Text className={css(styles.signup)}>
        <Trans
          i18nKey="password.token.signup"
          components={[<Link className={css(styles.link)} to={SIGNUP_PAGE} />]}
        />
      </Text>
    </div>
  );
}

const styles: FelaStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3rem 0rem',
    '@media (min-width: 320px)': {
      width: '17rem',
    },
    '@media (min-width: 768px)': {
      width: '22rem',
    },
    '@media (min-width: 1728px)': {
      width: '25.5rem',
    },
  },
  logo: {
    alignSelf: 'center',
    paddingBottom: '1.5rem',
  },
  title: {
    alignSelf: 'center',
    color: colors.text.lightGray,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
    margin: '0.5rem 0rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  forgotPassword: {
    alignSelf: 'center',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    marginBottom: '3rem',
  },
  signup: {
    alignSelf: 'center',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
  },
  link: {
    textDecoration: 'underline',
  },
  alert: {
    lineHeight: '1.375rem',
    fontSize: '0.875rem',
    margin: '1rem 0',
    width: '100%',
  },
};
