import * as yup from 'yup';

import i18n from '../../../../i18n';

const REQUIRED_MESSAGE = i18n.t(
  'password.forgotPasswordForm.validations.required',
);
const EMAIL_INVALID_FORMAT = i18n.t(
  'password.forgotPasswordForm.validations.login.invalidEmailFormat',
);
const PHONE_NUMBER_OR_USERNAME_INVALID_FORMAT = i18n.t(
  'password.forgotPasswordForm.validations.login.invalidPhoneNumberOrUsernameFormat',
);

const PHONE_NUMBER_REGEX = /(?:\()[0-9]{2}(?:\))\s?\d?\s?[0-9]{4}(?:-)[0-9]{4}$/;
const USERNAME_REGEX = /^[a-zA-Z]+$/;

const PHONE_NUMER_AND_USERNAME_REGEX = new RegExp(
  `(${PHONE_NUMBER_REGEX.source})|(${USERNAME_REGEX.source})`,
);

const validateEmail = () => {
  return yup.string().required(REQUIRED_MESSAGE).email(EMAIL_INVALID_FORMAT);
};

const validatePhoneAndUsername = () => {
  return yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(
      PHONE_NUMER_AND_USERNAME_REGEX,
      PHONE_NUMBER_OR_USERNAME_INVALID_FORMAT,
    );
};

const validationSchema = yup.object({
  login: yup.lazy((value) => {
    if (value.includes('@')) return validateEmail();

    return validatePhoneAndUsername();
  }),
});

export default validationSchema;
