import { useMutation } from '@apollo/client';

import {
  VALIDATE_PHONE_NUMBER,
  ValidatePhoneNumberMutation,
} from '../../../api/graphql/mutations';

type ValidatePhoneNumberMutationParams = {
  phoneNumber: string;
};

type useValidatePhoneNumberProps = {
  onCompleted?: (data: ValidatePhoneNumberMutation) => void;
};

export default function useValidatePhoneNumber({
  onCompleted,
}: useValidatePhoneNumberProps) {
  const [execute, { loading }] = useMutation<ValidatePhoneNumberMutation>(
    VALIDATE_PHONE_NUMBER,
    {
      onCompleted,
    },
  );

  const validatePhoneNumber = ({
    phoneNumber,
  }: ValidatePhoneNumberMutationParams) =>
    execute({
      variables: {
        input: { phoneNumber },
      },
    });

  return { loading, validatePhoneNumber };
}
