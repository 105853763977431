import { useMutation } from '@apollo/client';

import {
  AUTHENTICATE_USER,
  AuthenticateUserMutation,
} from '../../../api/graphql/mutations';

type AuthenticateUserMutationParams = {
  login: string;
  password: string;
};

type useAuthenticateUserProps = {
  onCompleted?: (data: AuthenticateUserMutation) => void;
};

export default function useAuthenticateUser({
  onCompleted,
}: useAuthenticateUserProps) {
  const [execute, { loading }] = useMutation<AuthenticateUserMutation>(
    AUTHENTICATE_USER,
    {
      onCompleted,
    },
  );

  const authenticate = ({ login, password }: AuthenticateUserMutationParams) =>
    execute({
      variables: {
        input: { login, password },
      },
    });

  return { loading, authenticate };
}
