import { getMobileOperatingSystem } from '../util';
import urls from '../commons/urls';

const appStoreURL = {
  'windows phone': urls.appStore.google,
  android: urls.appStore.google,
  ios: urls.appStore.apple,
};

export default function redirectToAppStore(timeout = 0) {
  const OS = getMobileOperatingSystem();

  if (OS === 'unknown') return;

  setTimeout(() => {
    window.location.assign(appStoreURL[OS]);
  }, timeout);
}
