import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useFela } from 'react-fela';
import { Link } from 'react-router-dom';

import { FelaStyle } from '../../../fela';

const LOGIN_PATH = '/login';

export default function LinkToLogin({ ...otherProps }) {
  const { css } = useFela();
  const { t } = useTranslation();

  const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.stopPropagation();
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...otherProps}>
      <div className={css(styles.container)}>
        <span>{t('user.registration.linkToLogin.alreadyRegistered')}</span>
        <span className={css(styles.whiteSpace)}> </span>
        <Link to={LOGIN_PATH} onClick={onClick} className={css(styles.link)}>
          {t('user.registration.linkToLogin.CTA')}
        </Link>
      </div>
    </div>
  );
}

const styles: FelaStyle = {
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    height: '1.25rem',
  },
  link: {
    margin: '0.25rem 0',
    textDecoration: 'underline',
  },
  whiteSpace: {
    whiteSpace: 'pre',
  },
};
