import React from 'react';
import { Alert, Button, Divider, Typography } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useFela } from 'react-fela';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatToPhone, formatToCPF, isCPF } from 'brazilian-values';

import { FelaStyle } from '../../../../fela';
import { FormInput, ItemLabel } from '../../../forms/components';
import colors from '../../../colors';
import MyworkLogo from '../../Logo';
import useSubdomain from '../../../hooks/useSubdomain';
import validationSchema from '../validations/forgotPasswordSchema';

const { Title, Text } = Typography;

export type ForgotPasswordFormData = {
  login: string;
};
export type ForgotPasswordFormProps = {
  loading?: boolean;
  onSubmit?: (data: ForgotPasswordFormData) => void;
  emailSent?: boolean;
};

const defaultValues: ForgotPasswordFormData = {
  login: '',
};
const SIGNUP_PAGE = '/cadastro';
const SIGNIN_PAGE = '/login';

const ONLY_NUMBERS_REGEX = /^\d+$/;
const SPECIAL_CHARACTERS_REGEX = /[-() ]/g;

const loginFormatter = (value: string) => {
  const defaultValue = value.replace(SPECIAL_CHARACTERS_REGEX, '');

  if (isCPF(defaultValue)) return formatToCPF(defaultValue);

  if (defaultValue.match(ONLY_NUMBERS_REGEX))
    return formatToPhone(defaultValue);

  return value;
};

export default function ForgotPasswordForm({
  onSubmit = () => {},
  loading = false,
  emailSent = false,
}: ForgotPasswordFormProps) {
  const { css } = useFela();
  const { t } = useTranslation();
  const subdomain = useSubdomain();
  const {
    control,
    handleSubmit: onFormSubmit,
  } = useForm<ForgotPasswordFormData>({
    criteriaMode: 'firstError',
    defaultValues,
    delayError: 200,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const managerApp = subdomain === 'app';

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.logo)}>
        <MyworkLogo height={50} />
      </div>
      <Title level={5} className={css(styles.title)}>
        {t('password.forgotPasswordForm.title')}
      </Title>
      <form onSubmit={onFormSubmit(onSubmit)}>
        <ItemLabel
          label={t('password.forgotPasswordForm.labels.login')}
          felaStyle={styles.itemLabel}
          required
        >
          <FormInput
            name="login"
            formatter={loginFormatter}
            control={control}
          />
        </ItemLabel>
        {emailSent && (
          <Alert
            className={css(styles.alert)}
            type="success"
            message={t('password.forgotPasswordForm.emailSent')}
            showIcon
            icon={<CheckCircleOutlined />}
          />
        )}
        {!emailSent && (
          <Button
            className={css(styles.submit)}
            type="primary"
            htmlType="submit"
            disabled={loading}
            loading={loading}
          >
            {t('password.forgotPasswordForm.submit')}
          </Button>
        )}
      </form>
      <Link
        className={css({ ...styles.link, ...styles.signin })}
        to={SIGNIN_PAGE}
      >
        {t('password.forgotPasswordForm.signin')}
      </Link>
      {managerApp && (
        <>
          <Divider />
          <Text className={css(styles.signup)}>
            <Trans
              i18nKey="password.forgotPasswordForm.signup"
              components={[
                <Link className={css(styles.link)} to={SIGNUP_PAGE} />,
              ]}
            />
          </Text>
        </>
      )}
    </div>
  );
}

const styles: FelaStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3rem 0rem',
    '@media (min-width: 320px)': {
      width: '17rem',
    },
    '@media (min-width: 768px)': {
      width: '22rem',
    },
    '@media (min-width: 1728px)': {
      width: '25.5rem',
    },
  },
  logo: {
    alignSelf: 'center',
    paddingBottom: '1.5rem',
  },
  title: {
    alignSelf: 'center',
    color: colors.text.lightGray,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
    margin: '0.5rem 0rem',
    marginBottom: '1rem',
  },
  itemLabel: {
    marginTop: '0',
    marginBottom: '1.5rem',
  },
  submit: {
    width: '100%',
    marginBottom: '1.5rem',
  },
  signin: {
    alignSelf: 'center',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    marginBottom: '1.5rem',
  },
  signup: {
    alignSelf: 'center',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    textAlign: 'center',
  },
  link: {
    textDecoration: 'underline',
  },
  alert: {
    lineHeight: '1.375rem',
    fontSize: '0.875rem',
    margin: '1rem 0',
    width: '100%',
  },
};
