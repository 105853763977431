import React, { createContext, PropsWithChildren, useState } from 'react';

import { Feature, Version as ProductVersion } from '../plans/types';

export type AllowedTrial = {
  beginTesting: string;
  testing: boolean;
  version: ProductVersion;
};

type AllowLists = {
  allowedFeatures: Feature[];
  allowedProducts: ProductVersion[];
  allowedTrials: AllowedTrial[];
};

export const PermissionsContext = createContext<{
  allowLists?: AllowLists;
  onAllowListsChange?: (allowLists: AllowLists) => void;
}>({});

const initialAllowLists: AllowLists = {
  allowedFeatures: [] as Feature[],
  allowedProducts: [] as ProductVersion[],
  allowedTrials: [] as AllowedTrial[],
};

export default function PermissionsProvider({
  children,
}: PropsWithChildren<unknown>) {
  const [allowLists, setAllowLists] = useState(initialAllowLists);

  const onAllowListsChange = (newAllowList: AllowLists) => {
    setAllowLists(newAllowList);
  };

  return (
    <PermissionsContext.Provider
      value={{
        allowLists,
        onAllowListsChange,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
}
