/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Alert, Button, Divider, Typography } from 'antd';
import { CheckCircleOutlined } from '@material-ui/icons';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Link, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useFela } from 'react-fela';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatToPhone, formatToCPF, isCPF } from 'brazilian-values';

import { FelaStyle } from '../../../../fela';
import { FormInput, FormPassword, ItemLabel } from '../../../forms/components';
import colors from '../../../colors';
import MyworkLogo from '../../Logo';
import useSubdomain from '../../../hooks/useSubdomain';
import validationSchema from '../validations/schema';

const { Text, Title } = Typography;

export type SigninFormData = {
  login: string;
  password: string;
};
export type SigninFormErrors = [field: keyof SigninFormData, message: string][];

export type SigninFormProps = {
  errors?: SigninFormErrors;
  loading?: boolean;
  onSubmit?: (data: SigninFormData) => void;
};

const defaultValues: SigninFormData = {
  login: '',
  password: '',
};
const SIGNUP_PAGE = '/cadastro';
const FORGOT_PASSWORD_URL = '/senha/nova';

const ONLY_NUMBERS_REGEX = /^\d+$/;
const SPECIAL_CHARACTERS_REGEX = /[-() ]/g;

const loginFormatter = (value: string) => {
  const defaultValue = value.replace(SPECIAL_CHARACTERS_REGEX, '');

  if (isCPF(defaultValue)) return formatToCPF(defaultValue);

  if (defaultValue.match(ONLY_NUMBERS_REGEX))
    return formatToPhone(defaultValue);

  return value;
};

export default function SigninForm({
  onSubmit = () => {},
  errors = [],
  loading = false,
}: SigninFormProps) {
  const { css } = useFela();
  const { t } = useTranslation();
  const subdomain = useSubdomain();
  const [searchParams] = useSearchParams();

  const { control, handleSubmit: onFormSubmit } = useForm<SigninFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const alertMessage = () => {
    if (errors.length === 0) return '';

    const [, message] = errors[0];
    return message;
  };
  const alertType = () => {
    if (errors.length === 0) return 'error';

    const [, message] = errors[0];
    return message?.split('.')[3].includes('inactiveUser')
      ? 'warning'
      : 'error';
  };

  const managerApp = subdomain === 'app';
  const passwordUpdated = searchParams.get('password_updated');

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.logo)}>
        <MyworkLogo height={50} />
      </div>
      <Title level={5} className={css(styles.title)}>
        {t('user.login.welcomeTitle.user')}
      </Title>
      {passwordUpdated === 'true' && (
        <div className={css(styles.passwordAlertContainer)}>
          <Alert
            className={css(styles.passwordAlert)}
            type="success"
            message={t('user.login.updatedPassword')}
            showIcon
            icon={<CheckCircleOutlined />}
          />
        </div>
      )}
      <form onSubmit={onFormSubmit(onSubmit)}>
        <ItemLabel
          felaStyle={styles.itemLabel}
          label={t('user.login.labels.username')}
          required
        >
          <FormInput
            name="login"
            formatter={loginFormatter}
            control={control}
          />
        </ItemLabel>
        <ItemLabel
          felaStyle={styles.itemLabel}
          label={t('user.login.labels.password')}
          required
        >
          <FormPassword name="password" control={control} />
        </ItemLabel>
        {errors && errors.length > 0 && (
          <Alert
            className={css(styles.alert)}
            type={alertType()}
            message={t(alertMessage())}
            showIcon
            icon={<CloseCircleOutlined />}
          />
        )}
        <Button
          className={css(styles.submitButton)}
          disabled={loading}
          htmlType="submit"
          loading={loading}
          type="primary"
        >
          {t('user.login.submitButton')}
        </Button>
      </form>
      <Link className={css(styles.forgotPassword)} to={FORGOT_PASSWORD_URL}>
        {t('user.login.forgotPassword')}
      </Link>
      {managerApp && (
        <>
          <Divider />
          <Text className={css(styles.createAccount)}>
            <Trans
              i18nKey="user.login.createAccount"
              components={[
                <Link
                  className={css(styles.createAccountLink)}
                  to={SIGNUP_PAGE}
                />,
              ]}
            />
          </Text>
        </>
      )}
    </div>
  );
}

const styles: FelaStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3rem 0rem',
    '@media (min-width: 320px)': {
      width: '17rem',
    },
    '@media (min-width: 768px)': {
      width: '22rem',
    },
    '@media (min-width: 1728px)': {
      width: '25.5rem',
    },
  },
  logo: {
    alignSelf: 'center',
    paddingBottom: '1.5rem',
  },
  title: {
    alignSelf: 'center',
    color: colors.text.lightGray,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
    margin: '1.5rem 0rem',
    textAlign: 'center',
  },
  itemLabel: {
    marginTop: '0',
    marginBottom: '1.5rem',
  },
  alert: {
    lineHeight: '1.375rem',
    fontSize: '0.875rem',
    margin: '1rem 0',
    width: '100%',
  },
  passwordAlert: {
    lineHeight: '1.375rem',
    fontSize: '0.875rem',
    width: '100%',
  },
  passwordAlertContainer: {
    paddingBottom: '1.5rem',
  },
  submitButton: {
    width: '100%',
    margin: '0',
  },
  forgotPassword: {
    alignSelf: 'center',
    margin: '1.5rem 0',
    fontSize: '0.875rem',
    textDecoration: 'underline',
  },
  createAccount: {
    alignSelf: 'center',
    color: colors.text.dark,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    textAlign: 'center',
  },
  createAccountLink: {
    textDecoration: 'underline',
  },
};
