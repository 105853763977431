export const gtmAuth = process.env.GTM_AUTH;
export const gtmPreview = process.env.GTM_PREVIEW;
export const gtmId = process.env.GTM_ID;

export const gtmScriptTagId = 'mywork-gtm';

export const gtmSnippet = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`;

export default function setupTagManagerScript() {
  if (document.getElementById(gtmScriptTagId)) return;

  const script = document.createElement('script');

  const attributes = {
    id: gtmScriptTagId,
    async: true,
    type: 'text/javascript',
    text: gtmSnippet,
  };

  Object.assign(script, attributes);

  document.head.appendChild(script);
}
