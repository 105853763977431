import { useMutation } from '@apollo/client';

import {
  SIGNUP_USER,
  SignupUserMutation,
} from '../../../api/graphql/mutations';

type UserSignupMutationParams = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
};

type useUserSignupProps = {
  onCompleted?: (data: SignupUserMutation) => void;
};

export default function useUserSignup({ onCompleted }: useUserSignupProps) {
  const [execute, { loading }] = useMutation<SignupUserMutation>(SIGNUP_USER, {
    onCompleted,
  });

  const signUp = ({
    email,
    firstName,
    lastName,
    password,
    phoneNumber,
  }: UserSignupMutationParams) =>
    execute({
      variables: {
        input: {
          email,
          firstName,
          lastName,
          password,
          phoneNumber,
        },
      },
    });

  return { loading, signUp };
}
