import { GrowthBook, JSONValue } from '@growthbook/growthbook-react';
import fireEvent from '../analytics/fireEvent';
import { experimentViewed } from './analytics/events';

const apiHost = process.env.GROWTHBOOK_API_HOST;
const clientKey = process.env.GROWTHBOOK_CLIENT_KEY;
const enableDevMode = process.env.NODE_ENV === 'development';

const growthbook = new GrowthBook({
  apiHost,
  clientKey,
  enableDevMode,
  subscribeToChanges: true,
  trackingCallback: (experiment, variation) => {
    fireEvent({
      ...experimentViewed,
      experimentId: experiment.key,
      variationId: variation.key,
    });
  },
});

export default growthbook;

export const updateAttributes = (
  attributes: Record<string, JSONValue | undefined>,
) => {
  const currentAttributes = growthbook.getAttributes();

  growthbook.setAttributes({
    ...currentAttributes,
    ...attributes,
  });
};
