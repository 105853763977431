export default function pageReadyListener(functionToRun) {
  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    functionToRun();
  } else {
    document.addEventListener('DOMContentLoaded', functionToRun);
  }
}
