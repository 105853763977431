import * as yup from 'yup';

import i18n from '../../../../i18n';

const PASSWORD_MIN_LENGTH = 6;
const REQUIRED_MESSAGE = i18n.t('signupForm.validations.required');

const PASSWORD_MIN_LENGTH_MESSAGE = i18n.t(
  'signupForm.validations.password.minLength',
  {
    minLength: PASSWORD_MIN_LENGTH,
  },
);
const CONFIRM_PASSWORD_INVALID = i18n.t(
  'signupForm.validations.confirmPassword.invalidFormat',
);

const validationSchema = yup.object({
  password: yup
    .string()
    .min(PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH_MESSAGE)
    .required(REQUIRED_MESSAGE),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], CONFIRM_PASSWORD_INVALID)
    .required(REQUIRED_MESSAGE),
});

export default validationSchema;
