import React, { useState } from 'react';

import { appRecoverPasswordEmailSent } from '../analytics/events';
import fireEvent from '../../../../analytics/fireEvent';
import ForgotPasswordFormComponent, {
  ForgotPasswordFormData,
} from '../components/ForgotPasswordForm';
import useResetUserPassword from '../hooks/useResetUserPassword';

export default function ForgotPasswordForm() {
  const [emailSent, setEmailSent] = useState(false);

  const onCompleted = () => {
    setEmailSent(true);
    fireEvent(appRecoverPasswordEmailSent);
  };
  const { loading, resetPassword } = useResetUserPassword({
    onCompleted,
  });

  const onSubmit = ({ login }: ForgotPasswordFormData) => {
    resetPassword({ login: formatLogin(login) });
  };

  return (
    <ForgotPasswordFormComponent
      loading={loading}
      onSubmit={onSubmit}
      emailSent={emailSent}
    />
  );
}

const formatLogin = (login: string) => {
  if (login.includes('@')) return login;

  return login.replace(/[-() .]/g, '');
};
