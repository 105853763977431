import { JSONValue, useFeatureValue } from '@growthbook/growthbook-react';

export type WidenPrimitives<T> = T extends string
  ? string
  : T extends number
  ? number
  : T extends boolean
  ? boolean
  : T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useGetFeatureValue = <T extends JSONValue = any>(
  featureKey: string,
  fallbackValue: T,
): WidenPrimitives<T> => {
  const featureValue = useFeatureValue(featureKey, fallbackValue);

  return featureValue;
};

export default useGetFeatureValue;
